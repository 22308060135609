import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  mainContent,
  introSection,
  row,
  col12,
  marginTopSubstitute,
  borderBlue,
  borderPurple,
  borderGreen,
  borderSkyBlue,
  borderSpringGreen,
  desktopNavContainer,
  desktopNav,
  desktopContainer,
  sectionContent,
  hasSideNav,
  moduleNavButton,
  barTop,
  barMid,
  barBot,
  moduleNavOpen,
  moduleNavButtonOpen,
} from "./subpage.module.scss";


const HealthcareOekosystemPage = () => {
  const [mobileModuleNavIsVisible, setMobileModuleNavIsVisible] =
    React.useState(false);

  const handleMobileModuleNavClick = () => {
    setMobileModuleNavIsVisible(!mobileModuleNavIsVisible);
  };

  return (
    <Layout pageTitle="Healthcare Ökosystem" neutralPage={true} smoothScroll={true}>
      <main className={mainContent}>
        <section className={`${introSection} ${hasSideNav}`}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <h1>Healthcare Ökosystem</h1>
                <p className="text-medium">
                flexibel und passgenau – Kaum je waren die Herausforderungen an Menschen, Systeme 
                und Prozesse im Gesundheitswesen so gross wie heute. Dazu kommt der rasante Strukturwandel. 
                Neue Regulatoren, neue Anforderungen, neue Ansprüche von Therapie, Betrieb und Finanzen entstehen 
                laufend.
                </p>
                <p className="text-medium">
                Das bedeutet flexible IT-Strukturen, eine leistungsstarke Daten- und Prozessintegration 
                sowie eine interoperabel Plattform.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <div className={desktopNavContainer}>
          <div
            className={`${desktopNav} ${
              mobileModuleNavIsVisible ? moduleNavOpen : ""
            }`}
          >
            <div>
              <h3>Partner</h3>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#ship"
              >
                SHIP
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#leitwert-device-hub"
              >
                Leitwert Device Hub
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#wundmanagement"
              >
                imito
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#sublimd"
              >
                Sublimd
              </Link>
              

              <a href="https://www.united-security-providers.ch/" target="_blank" rel="noreferrer">United Security Providers</a>

              <a href="https://www.dmi.de/" target="_blank" rel="noreferrer">DMI</a>

              <a href="https://inacta.ch/produkte/" target="_blank" rel="noreferrer">inadox Mail</a>

              <a href="https://www.netcetera.com/de/home.html" target="_blank" rel="noreferrer">Netcetera</a>

          
             
             
            </div>
          </div>
          <div className={desktopContainer}>
            <section className={borderBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <p>
                    <span className="text-blue">Im komplexen Umfeld der Spitäler, das stark reglementiert ist
                  und höchsten Qualitäts- Anforderungen entsprechen muss, sind
                  neue Perspektiven für effiziente Prozesse gefordert. </span> Diese
                  benötigen eine leistungsstarke, entwicklungsfähige und
                  zukunftsträchtige IT-Architektur, die sich dem raschen Wandel
                  flexibel anpassen lässt.
                    </p>

                    <p>
                  Um diese Anforderungen zu meistern, haben the i-engineers und
                  Netcetera zusammen das Healthcare Ökosystem entwickelt. Es ist
                  geprägt von einer hohen Daten- und Prozessintegration und
                  einer interoperablen Plattform.
                </p>

                <StaticImage
                  src="../assets/healthcare-oekosystem.svg"
                  alt=""
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />

<h3 className={marginTopSubstitute}>
                  Daten- und Prozessintegration
                </h3>
                <ul>
                  <li>Flächendeckende Datensammlung</li>
                  <li>
                    Zugriff für diverse Applikationen auf die gleichen Daten
                  </li>
                  <li>Datenhaltung für interne und Kommunikations Prozesse</li>
                  <li>Übergreifende Prozesssteuerung</li>
                  <li>
                    Kopplung von spezialisierten Apps mit eigener Fachlogik
                  </li>
                  <li>Alle Daten</li>
                  <ul>
                  <li>Medikation</li>
                  <li>Berichte</li>
                  <li>Befunde</li>
                  <li>Bilder</li>
                  <li>Dicoms</li>
                  <li>Labordaten</li>
                  <li>Videos</li>
                  <li>Aufträge</li>
                  <li>Leistungen...</li>
                  </ul>
                </ul>

                <h3 className={marginTopSubstitute}>Interoperable Plattform</h3>
                <ul>
                  <li>Diverse User Interfaces, je nach Anspruchsgruppe</li>
                  <li>Alle Devices</li>
                  <ul>
                  <li>PCs</li>
                  <li>Tabs</li>
                  <li>Smart Phones</li>
                  <li>Monitore...</li>
                  </ul>
                  <li>Schnelle Entwicklung von neuen Apps;</li>
                  <li>Auch BYOD Alle Daten:</li>
                  <ul>
                  <li>Medikation</li>
                  <li>Berichte</li>
                  <li>Befunde</li>
                  <li>Bilder</li>
                  <li>Dicoms</li>
                  <li>Labordaten</li>
                  <li>Videos</li>
                  <li>Aufträge</li>
                  <li>Leistungen...</li>
                  </ul>
                </ul>   

                <h3 className={marginTopSubstitute}>
                  Übersicht ist fast alles
                </h3>
                <ul>
                  <li>
                    Alle relevanten Informationen zum Patienten stehen bereit;
                  </li>
                  <li>Sämtliche Fallinformationen;</li>
                  <li>Gesamte Krankengeschichte.</li>
                </ul>

                <h3 className={marginTopSubstitute}>
                  Flexibel, erweiterungsfähig und zukunftsorientiert
                </h3>
                <ul>
                  <li>
                    Reaktion auf organisatorische oder gesetzliche Änderungen;
                  </li>
                  <li>Interne und externe Vernetzung;</li>
                  <li>Standardisierte Schnittstellen für Drittanbieter;</li>
                  <li>
                    Immer mehr spezialisierte Apps, Geräte, Applikationen, ....
                  </li>
                  <li>
                    Spezialisierte Apps von Start-ups und etablierten Anbietern.
                  </li>
                </ul>


                    
                  </div>
                </div>
              </div>
            </section>


            <section className={borderSpringGreen} id="ship">
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                  <div style={{ marginBottom: 16 }}>
                      <StaticImage
                        src="../assets/ship-partner.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        formats={["auto", "webp", "avif"]}
                        width={180}
                      />
                    </div>
                    <h1>SHIP (Swiss Health Information Processing)</h1>
                    <p className="text-medium">
                    Mit SHIP können administrative Prozesse (Informations- und Datenaustausch) 
                    zwischen Leistungserbringern und Kostenträgern automatisiert, digitalisiert und 
                    harmonisiert abgewickelt werden.
                    </p>
                    <a href="https://ship-standard.ch/" target="_blank" rel="noreferrer">ship-standard.ch</a>
                  </div>
                </div>
              </div>
            </section>



            <section id="leitwert-device-hub" className={borderPurple}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                  <div style={{ marginBottom: 16 }}>
                      <StaticImage
                        src="../assets/Leitwert-partner.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        formats={["auto", "webp", "avif"]}
                        width={180}
                      />
                    </div>
                     <h1>Leitwert Device Hub</h1>
                    <p className="text-medium">
                    Der Device Hub ermöglicht es Spitälern, die steigende Anzahl
                    tragbarer medizinischer Geräte in Arbeitsabläufe zu
                    integrieren. Damit können ganz neue Ansätze zur kontinuierlichen Patientenüberwachung 
                    (z.B. EKG, Sauerstoffsättigung,
                    Aktivität, Temperatur) und zur Effizienzsteigerung in
                    Pflegeprozes- sen umgesetzt werden. Dieselbe Infrastruktur
                    erlaubt auch die effiziente Durchführung von klinischen
                    Studien mit Patienten innerhalb und ausserhalb des Spitals.
                    </p>
                    <a href="https://www.leitwert.ch/technology/device-hub/" target="_blank" rel="noreferrer">leitwert.ch/technology/device-hub</a>
                  </div>
                </div>

                

              
              </div>
            </section>

            <section id="wundmanagement" className={borderSkyBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <div style={{ marginBottom: 16 }}>
                      <StaticImage
                        src="../assets/imito-partner.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        formats={["auto", "webp", "avif"]}
                        width={180}
                      />
                    </div>
                    <h1>imito – Die Zukunft des digitalen Wundmanagements</h1>
                    <p className="text-medium">
                    Verbessern Sie Ihren klinischen Alltag mit den Apps von imito.
                    KI-gestützte, sichere und integrierte Apps, um Ihre klinischen
                    Prozesse einfacher und effizienter zu gestalten.
                    </p>
                    <a href="https://imito.io/de/" target="_blank" rel="noreferrer">imito.io</a>

                 
                    
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                  <p>Imito bietet eine Vielzahl von Produkten für das Gesundheitswesen an, darunter:</p>
                    <ul>
                      <li>imitoCam: Eine mobile Anwendung, mit der medizinisches Fachpersonal Bilder und Berichte direkt am Patientenbett erfassen kann.</li>
                      <li>imitoMeasure: Ein Tool, das es ermöglicht, anatomische Maße und Messungen präzise und effizient zu erfassen.</li>
                      <li>imitoConnect: Eine Plattform, die die nahtlose Integration der erfassten Daten in das Krankenhausinformationssystem ermöglicht.</li>
                      <li>imitoInsight: Eine leistungsstarke Datenanalyseplattform, die medizinische Informationen nutzt, um wertvolle Erkenntnisse 
                        für die Verbesserung der Patientenversorgung zu gewinnen.</li>
                    </ul>

                  </div>        
                </div>

 

              </div>
            </section>

            <section id="sublimd" className={borderGreen}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <div style={{ marginBottom: 16 }}>
                      <StaticImage
                        src="../assets/sublimd-partner.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        formats={["auto", "webp", "avif"]}
                        width={180}
                      />
                    </div>
                    <h1>Sublimd</h1>
                    <p className="text-medium">
                    Reduktion des Dokumentationsaufwandes dank strukturierter
                    Datenerhebung – sublimd ist eine Software-Plattform, welche
                    administrative Arbeiten auf ein Minimum reduziert, die
                    medizinische Behandlungsqualität steigert und die klinische
                    Forschung revolutioniert.
                    </p>
                    <a href="https://sublimd.com/de/" target="_blank" rel="noreferrer">sublimd.com</a>

                  </div>
                </div>

                <div className={row}>
          </div>

              </div>
            </section>

          </div>
        </div>
      </main>

      <button
        className={`${moduleNavButton} ${
          mobileModuleNavIsVisible ? moduleNavButtonOpen : ""
        }`}
        onClick={handleMobileModuleNavClick}
      >
        <span className={barTop}></span>
        <span className={barMid}></span>
        <span className={barBot}></span>
      </button>
    </Layout>



  );
};

export default HealthcareOekosystemPage;
